import { FunctionComponent } from "react"
import { ReactDatePickerCustomHeaderProps } from "react-datepicker"
import { format } from "date-fns"

import Icon from "@/components/Icon"
import { cn } from "@/helpers/classNames"
import { getLocale, resolveLanguage } from "@/helpers/i18n"

const styles = {
  container:
    "flex items-center bg-neutral-100 pl-4 pr-2 py-2 gap-x-2 font-button-medium text-neutral-700 select-none",
  dateContainer: "grow",
  iconContainer: (isDisabled: boolean) => {
    return cn([
      "cursor-pointer p-2",
      isDisabled && "pointer-events-none text-neutral-400",
    ])
  },
  iconLeft: cn("-rotate-180"),
}

type Props = ReactDatePickerCustomHeaderProps

const CustomHeader: FunctionComponent<Props> = ({
  date,
  decreaseMonth,
  increaseMonth,
  nextMonthButtonDisabled,
  prevMonthButtonDisabled,
}) => {
  const language = resolveLanguage()
  const dateFormat = language === "ja" ? "yyyy'年'MM'月'" : "MM/yyyy"

  return (
    <div className={styles.container}>
      <div className={styles.dateContainer}>
        {format(date, dateFormat, { locale: getLocale() })}
      </div>
      <div
        onClick={decreaseMonth}
        className={styles.iconContainer(prevMonthButtonDisabled)}
      >
        <Icon
          name="chevron"
          className={styles.iconLeft}
          isDisabled={prevMonthButtonDisabled}
        />
      </div>
      <div
        onClick={increaseMonth}
        className={styles.iconContainer(nextMonthButtonDisabled)}
      >
        <Icon name="chevron" isDisabled={nextMonthButtonDisabled} />
      </div>
    </div>
  )
}

export default CustomHeader
