import { forwardRef } from "react"
import { useTranslation } from "react-i18next"
import * as SelectPrimitive from "@radix-ui/react-select"
import { ChevronDown } from "lucide-react"

import Icon from "@/components/Icon"
import {
  Select,
  SelectContent,
  SelectGroup,
  selectItemVariants,
  selectTriggerVariants,
  SelectValue,
} from "@/components/ui/select"
import { cn } from "@/helpers/classNames"

type AgentStatus = connect.AgentStateType

type Props = {
  disabledInManualCalling: boolean
  onChange: (status: AgentStatus) => void
  status: AgentStatus
}

export function AgentStatusSwitcher({
  disabledInManualCalling,
  onChange,
  status,
}: Props) {
  const { t } = useTranslation()
  const isLoading = status === connect.AgentStateType.INIT

  return (
    <Select
      value={isLoading ? "" : status}
      onValueChange={onChange}
      disabled={isLoading || disabledInManualCalling}
    >
      <SelectTrigger
        className={cn("font-button-large h-auto w-[190px] py-2 pl-4 pr-2")}
      >
        <SelectValue
          placeholder={t("header.agentSelectOptions.initializing")}
          isSentryUnmask
        />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup className="flex flex-col gap-2 p-3" isSentryUnmask>
          <StatusOption status={connect.AgentStateType.ROUTABLE} />
          <StatusOption status={connect.AgentStateType.OFFLINE} />
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}

function StatusOption({ status }: { status: AgentStatus }) {
  const { t } = useTranslation()

  return (
    <SelectItem value={status}>
      <div className="flex items-center gap-2">
        <AgentStatusPill status={status} />
        <span>{t(`header.agentSelectOptions.${status}`)}</span>
      </div>
    </SelectItem>
  )
}

function AgentStatusPill({ status }: { status: AgentStatus }) {
  switch (status) {
    case connect.AgentStateType.ROUTABLE:
      return <Icon size="sm" name="available" />
    case connect.AgentStateType.OFFLINE:
      return <Icon size="sm" name="offline" />
    default:
      return null
  }
}

/**
 * Custom SelectTrigger component with a specific icon
 */
const SelectTrigger = forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ children, className, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={cn(selectTriggerVariants({ size: "xl" }), className)}
    {...props}
  >
    {children}
    <SelectPrimitive.Icon asChild>
      <ChevronDown className="size-5" />
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
))
SelectTrigger.displayName = "CustomSelectTrigger"

/**
 * Custom SelectItem component without the "check" icon before the selected item
 */
const SelectItem = forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ children, className, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(selectItemVariants({ size: "xl" }), "pl-4")}
    {...props}
  >
    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
))
SelectItem.displayName = "CustomSelectItem"
