import { PropsWithChildren } from "react"
import { Outlet } from "react-router-dom"
import { useAtomValue } from "jotai"

import Header from "@/components/Header"
import { useLayoutHook } from "@/components/layout/hook"
import Loader from "@/components/layout/Loader"
import Sidebar from "@/components/Sidebar"
import { ccpModeAtom, modalModeAtom } from "@/helpers/atoms"
import { cn } from "@/helpers/classNames"
import { hasValue } from "@/helpers/typeguards"
import ErrorPage from "@/pages/ErrorPage"

const styles = {
  layoutContainer: (isModalOpen: boolean) =>
    cn(["flex size-full flex-col", isModalOpen && "overflow-hidden"]),
  body: "flex flex-row grow",
  pageContainer: "bg-neutral-200 grow overflow-x-hidden",
}

const Layout = ({ children }: PropsWithChildren) => {
  const { agentProfile } = useLayoutHook()
  const ccpMode = useAtomValue(ccpModeAtom)
  const modalMode = useAtomValue(modalModeAtom)

  return (
    <div className={styles.layoutContainer(hasValue(modalMode))}>
      <Header agentProfile={agentProfile} />
      <div className={styles.body}>
        <Sidebar />
        <div className={styles.pageContainer}>
          {ccpMode.current === "LOADING" && <Loader isSlow={ccpMode.isSlow} />}
          {ccpMode.current === "INITIALIZED" && <Outlet />}
          {/* If the CCP panel fails to initialize properly (oauth error, exceeded the number of retries, etc.) */}
          {ccpMode.current === "ERROR" && <ErrorPage />}
        </div>
      </div>
      {children}
    </div>
  )
}

export default Layout
